import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            ulyanovsk
          }
        }
      }
    `
  )

  const metaDescription = "Проститутки Ульяновска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Ульяновска готовы ко встрече в приватной обстановке." || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://vipdosug.org/prostitutki-ulyanovska/"/>
      <link rel="alternate" href="https://vipdosug.org/prostitutki-ulyanovska/" hreflang="ru"/>
      <link rel="amphtml" href="https://vipdosug.org/prostitutki-ulyanovska/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	    <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="vipdosug.org"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.ulyanovsk}/>
      <meta property="og:url" content="https://vipdosug.org/prostitutki-ulyanovska/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.ulyanovsk}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.ulyanovsk}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://vipdosug.org/vipdosug.atom" title="Проститутки России, лучшие индивидуалки | VipDosug.org"/>
      <script type="application/ld+json">
  {`
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": "https://vipdosug.org/",
    "name": "VipDosug.Org",
    "sameAs": [
        "https://www.youtube.com/@dosugcz8671",
        "https://flipboard.com/@dosug",
        "https://vk.com/dosug.cz_ru",
        "https://t.me/DosugOfficialle",
        "https://twitter.com/DosugczOfficial",
        "https://www.facebook.com/dosugru.net",
        "https://ru.pinterest.com/dosugru/"
    ]
}
  `}
</script>
<script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org",
    "author": {
        "@type": "WebPage",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/"
    },
    "@type": "Article",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://vipdosug.org/prostitutki-ulyanovska/"
    },
    "headline": "Проститутки Ульяновска, лучшие индивидуалки | VipDosug.org",
    "description": "Проститутки Ульяновска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Ульяновска готовы ко встрече в приватной обстановке.",
    "articleBody": "Хотите ярких сексуальных впечатлений? Хотите чтобы ваше тело нежно ласкали пара умелых женских рук? Хотите неистового бурного секса до полной потери сил? Все это может быть не только вашими откровенными эротическими фантазиями, но и самой настоящей реальностью. И воплотить сказку в быль вам помогут горячие и сладенькие индивидуалки Ульяновска. Эти прекрасные гетеры знают все сакральные тайны искусства любви и поэтому они без слов поймут ваши желания и исполнят любые фантазии. С кем вы хотите провести эту ночь - с наивной девочкой или со страстной львицей? Каких ощущений жаждет ваше истомленное тело - оральных, анальных или классических? Выбирайте, ведь проститутки Ульяновска владеют всеми приемами и видами секса в совершенстве. Хотите просто расслабиться и получить максимум удовольствия в компании прекрасной дамы? Прекрасные гетеры Ульяновска уже ждут вашего звонка!",
    "image": [
        "https://vipdosug.org/images/og-ulyanovsk.png"
    ],
   
    "articleSection": "Развлечения",
    "publisher": {
        "@type": "Organization",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/",
        "logo": {
            "@type": "ImageObject",
            "url": "https://vipdosug.org/images/amp.png",
            "width": 264,
            "height": 59
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo